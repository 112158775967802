<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo de Habitación - Nuevo</strong>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" @submit.prevent="AddRoom">
              <b-row>
                <b-col md="3"></b-col>
                <b-col md="6">
                  <b-form-group label="Tipo de Habitacion :" required>
                    <select ref="room"
                      class="form-control"
                      v-model="room.id_type_room" >
                      <option value="">-- Seleccione --</option>
                      <option v-for="item of type_room" :key="item.id_type_room" :value="item.id_type_room">
                        {{ item.name }}
                      </option>
                    </select>
                    <small v-if="errors.id_type_room" class="form-text text-danger">Seleccione un tipo de habitación</small>
                  </b-form-group>
                </b-col>
                <b-col md="3"></b-col>

                <b-col md="3"></b-col>
                <b-col md="6">
                  <b-form-group label="Nro Habitación:">
                    <b-form-input
                      type="text"
                      ref="room_number"
                      v-model="room.room_number"
                    ></b-form-input>
                    <small v-if="errors.room_number" class="form-text text-danger">Ingrese un nro de habitación</small>
                  </b-form-group>
                </b-col>
                <b-col md="3"></b-col>

                <b-col md="3"></b-col>
                <b-col md="6">
                  <b-form-group label="Precio:">
                    <b-form-input
                      type="number" 
                      step="any"
                      class="text-right"
                      ref="price"
                      v-model="room.price"
                    ></b-form-input>
                    <small v-if="errors.price" class="form-text text-danger">Ingrese un precio</small>
                  </b-form-group>
                </b-col>
                <b-col md="3"></b-col>

                <b-col md="3"></b-col>
                <b-col md="6">
                  <b-form-group label="Foto:">
                    <b-form-file
                      @change="onFileChange"
                      placeholder="Seleccione un foto..."
                      drop-placeholder="Suelta la imagen aquí..."
                    ></b-form-file>
                  </b-form-group>
                </b-col>
                <b-col md="3"></b-col>

                <b-col md="3"></b-col>
                <b-col md="6">
                  <b-form-group label="Descripción :" required>
                    <b-form-textarea
                      v-model="room.description"
                      rows="3"
                      max-rows="6"
                    ></b-form-textarea>
                  </b-form-group>
                </b-col>
                <b-col md="3"></b-col>

                <b-col md="3"></b-col>
                <b-col md="6">
                  <b-form-group label="Estado :" label-for="input-1">
                    <select
                      ref="state"
                      v-model="room.state"
                      class="form-control">
                      <option value="1">Activo</option>
                      <option value="0">Inactivo</option>
                    </select>
                  </b-form-group>
                </b-col>
                <b-col md="3"></b-col>

                <b-col md="3"></b-col>
                <b-col md="6">
                  <b-button type="submit" class="form-control btn-primary">GUARDAR</b-button>
                </b-col>
              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";

export default {
  name: "TypeRoomAdd",
  data() {
    return {
      module: "Room",
      room: {
        id_type_room: "",
        room_number: "",
        photo: "",
        price:0,
        description: "",
        state: 1,
      },
      type_room: [],
      //errors
      errors: {
        id_type_room: false,
        room_number: false,
        price: false,
      },
      validate: false,
    };
  },
  mounted() {
    this.ListTypeRoom();
  },
  methods: {
    AddRoom,
    Validate,
    onFileChange,
    ListTypeRoom,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
  },
};

function ListTypeRoom() {
  let me = this;
  let url = this.url_base + "active-type-room";

  axios({
    method: "GET",
    url: url,
    headers: {
      token: this.token,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.type_room = response.data.result;
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
    .catch((error) => {});
}

function onFileChange(e) {
  // e.preventDefault();
  this.room.photo = e.target.files[0];
}

function AddRoom() {
  // validacion de campos obligatorios
  this.Validate();
  if (this.validate) {
    return false;
  }

  let me = this;
  let url = this.url_base + "room/add/";

  let data = new FormData();
  data.append("id_type_room", this.room.id_type_room);
  data.append("room_number", this.room.room_number);
  data.append("photo", this.room.photo);
  data.append("price", this.room.price);
  data.append("description", this.room.description);
  data.append("state", this.room.state);

  axios({
    method: "POST",
    url: url,
    data: data,
    headers: {
      "Content-Type": "multipart/form-data",
      token: this.token,
      module: this.module,
      role: 2,
    },
  })
    .then(function (response) {
      if (response.data.status == 201) {
        me.room.id_type_room = "";
        me.room.room_number = "";
        me.room.photo = "";
        me.room.price = 0;
        me.room.description = "";
        me.room.state = 1;
        Swal.fire("Sistema", "Se ha creado la habitación", "success");
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
    .catch((error) => {});
}

function Validate() {
  this.errors.id_type_room = this.room.id_type_room.length == 0 ? true : false;
  this.errors.room_number = this.room.room_number.length == 0 ? true : false;
  this.errors.price = this.room.price.length == 0 ? true : false;
  this.validate = this.errors.id_type_room == true ? true : false;
  this.validate = this.errors.room_number == true ? true : false;
  this.validate = this.errors.price == true ? true : false;
}
</script>
